<template>
  <div class="mb-5">
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="card">
          <div class="card-header">Exportar Dados Cesta - Mercado</div>
          <div class="card-body p-3">
            <div class="alert alert-success fs-6" v-if="sucesso.length">
              <ul class="list-unstyled">
                <li v-for="(s, i) in sucesso" :key="i">{{ s }}</li>
              </ul>
            </div>
            <div class="alert alert-warning fs-6" v-if="alerta.length">
              <ul class="list-unstyled">
                <li v-for="(a, i) in alerta" :key="i">{{ a }}</li>
              </ul>
            </div>
            <div class="alert alert-danger fs-6" v-if="erro.length">
              <ul class="list-unstyled">
                <li v-for="(e, i) in erro" :key="i">{{ e }}</li>
              </ul>
            </div>
            <fieldset class="border p-3 mb-1 w-100">
              <legend class="float-none w-auto lead">Filtre por</legend>
              <b-row class="mb-2">
                <b-col cols="md-3 12">
                  <strong>Mês/Ano de Referência:</strong>
                </b-col>
                <b-col cols="md-4 12">
                  <b-form-select
                    v-model="mes"
                    :options="meses"
                    class="form-select"
                  >
                  </b-form-select>
                </b-col>
                <b-col cols="md-1 12 text-center">
                  <span>/</span>
                </b-col>
                <b-col cols="md-4 12">
                  <the-mask
                    class="form-control"
                    type="text"
                    mask="####"
                    :masked="true"
                    v-model="ano"
                    placeholder="Ano"
                  ></the-mask>
                </b-col>
              </b-row>
              <b-row class="mb-2 mt-3">
                <b-col cols="md-3 12">
                  <b>Período:</b>
                </b-col>
                <b-col cols="md-4 12">
                  <b-form-input
                    id="data_inicio"
                    type="date"
                    max="3000-12-31"
                    title="Data Início"
                    v-model="dataInicio"
                    @keyup.native.enter="gerarArquivo"
                  ></b-form-input>
                </b-col>
                <b-col cols="md-1 12 text-center">
                  <span>a</span>
                </b-col>
                <b-col cols="md-4 12">
                  <b-form-input
                    id="data_fim"
                    type="date"
                    max="##/##/####"
                    title="Data Fim"
                    v-model="dataFim"
                    @keyup.native.enter="gerarArquivo"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-3" align="right">
                <b-col>
                  <b-button
                    style="margin-right: 5px"
                    variant="outline-secondary"
                    @click="limpar"
                    title="Limpar filtros"
                  >
                    <font-awesome-icon :icon="['fas', 'eraser']" />
                    Limpar
                  </b-button>
                  <b-button variant="primary" @click="gerarArquivo()"
                    ><font-awesome-icon :icon="['fas', 'file-csv']" /> Gerar
                    Arquivo</b-button
                  >
                </b-col>
              </b-row>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from "../../helpers";

export default {
  components: {},
  data() {
    return {
      items: [{ text: "Exportar Dados Cesta - Mercado", active: true }],
      mes: null,
      ano: null,
      dataInicio: null,
      dataFim: null,
      sucesso: [],
      alerta: [],
      erro: [],
      meses: [
        { value: null, text: "Selecione o mês" },
        { value: 1, text: "Janeiro" },
        { value: 7, text: "Julho" },
      ],
    };
  },

  computed: {
    http() {
      return this.$store.getters.http;
    },
  },

  methods: {
    async gerarArquivo() {
      this.sucesso = [];
      this.alerta = [];
      this.erro = [];
      try {
        this.$store.commit("setLoading", true);
        let params = {
          mes: this.mes,
          ano: parseInt(this.ano),
          dataInicio: this.dataInicio,
          dataFim: this.dataFim,
        };
        const resp = await this.http.post(
          `/admin/export/dados-cesta-mercado`,
          params,
          {
            headers: { Authorization: `Bearer ${this.$store.getters.token}` },
          }
        );
        if (resp.status == 200 && resp.data.erro != "true") {
          this.sucesso = ["Dados exportados com sucesso"];
          helpers.downloadFile(resp.data, "dados_cesta_estudantes_mercado.csv");
        } else if (resp.data.erro == "true") {
          this.alerta = Object.values(resp.data.message).flat();
        }
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },

    limpar() {
      this.mes = null;
      this.ano = null;
      this.dataInicio = null;
      this.dataFim = null;
    },
  },

  mounted() {},
};
</script>

